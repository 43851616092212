import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/index.css"

import PartnerImage from "../images/smiling-man-gesturing-thumb-up_23-2147710923.jpg"
import BookRide from "../images/muvit-customer-request-screen.png"
import LandingPageImage from "../images/order-landing-page.svg"
//import PackagingProcess from "../images/muvit-packaging-process.svg"
//import PackagingProcess from "../images/logistics.svg"
//import PackagingProcess from "../images/ride-section.svg"
import PackagingProcess from "../images/muvit-packaging-process.svg"

import DownloadAppButton from "../components/download-app-buttons"

import Connect from "../images/icons/connect.svg"
import MobilePhone from "../images/icons/mobile-phone.svg"
import RealtimeTracking from "../images/icons/realtime-tracking.svg"
import Support from "../images/icons/support.svg"
import Distribution from "../images/icons/distribution.svg"
import HugeItem from "../images/icons/huge-item.svg"
import Service from "../images/icons/service2.svg"
import Transport from "../images/icons/transport2.svg"
import ItemMovement from "../images/icons/moving-with-items.svg"
import MovingSupplies from "../images/icons/moving3.svg"

//import partner images
import partner1 from "../images/partners/partner1-web.jpg"
import partner2 from "../images/partners/partner2-web.jpg"
import partner3 from "../images/partners/partner3-web.jpg"
import partner4 from "../images/partners/partner4-web.jpg"
import partner5 from "../images/partners/partner5-web.jpg"
import partner6 from "../images/partners/partner6-web.jpg"
import partner7 from "../images/partners/partner7-web.jpg"
import partner8 from "../images/partners/partner8-web.jpg"
import partner9 from "../images/partners/partner9-web.jpg"
import partner10 from "../images/partners/partner10-web.jpg"

const openGetQuote = e => {
  let get_quote = document.querySelector(".get-quote")
  window.scrollTo(0, -window.scrollY)
  let client_width = document.body.clientWidth
  if (!(client_width >= 320 && client_width <= 480)) return

  if (get_quote.classList.contains("get-quote-active")) return

  get_quote.classList.toggle("get-quote-active")
  document.body.classList.toggle("disable-scroll")
}

const closeGetQuote = e => {
  let get_quote = document.querySelector(".get-quote")
  let target = e.target
  if (!get_quote.classList.contains("get-quote-active")) return

  if (target.classList.contains("get-quote")) {
    get_quote.classList.toggle("get-quote-active")
    document.body.classList.toggle("disable-scroll")
  }
}

const IndexPage = () => (
  <Layout>
    <Helmet
      bodyAttributes={{
        class: "full-page home-page",
      }}
    />
    <SEO title="Home" />
    <section className="section-intro">
      <div className="section--content align-items-center">
        <div className="home--left--section">
          <h1 className="home--banner-text">
            Enjoy the convenience <br />
            of a safe and reliable <br />
            logistics service.
          </h1>
          <h2 className="home--banner-text-sub">
            get your deliveries sorted out
          </h2>
          <h4 className="home--banner-text-small">
            Wanna move something? We are 3 clicks away. <br /> Download the App
            to get started.
          </h4>
          <DownloadAppButton />
        </div>
        <div className="home--right--section">
          <div
            className="home-delivery--illustration"
            style={{ height: "390px" }}
          >
            <img src={PackagingProcess} alt="" />
          </div>
        </div>
        <div
          className="get-quote"
          // style={{ display: "flex", justifyContent: "center" }}
          onClick={closeGetQuote}
        >
          <p>Check the prices for different locations</p>
          {/* <ul className="quote-params"> */}
          {/* <li>
              <input typeof="text" placeholder="Pickup Address" name="" />
            </li>
            <li>
              <input typeof="text" placeholder="Destination Address" name="" />
            </li>
            <li>
              <input typeof="text" placeholder="Phone Number" name="" />
            </li>
            <li>
              <input typeof="text" placeholder="Email" name="" />
            </li>
            <li>
              <input typeof="text" placeholder="Full Name" name="" />
            </li> */}
          {/* </ul> */}
          <button
            className="cta cta--get--quote bg--green"
            onClick={openGetQuote}
          >
            <a href="/get-estimate">Get Estimate</a>
          </button>
        </div>
      </div>
    </section>
    <section className="on-board">
      <div className="section--content flex-section">
        <div className="on-board-steps">
          <div className="on-board-step" data-value="1">
            <h2>Request a ride in seconds</h2>
            <p>
              Choose your pickup and drop location and pick your desired vehicle
              we will take care of the rest.
            </p>
            <DownloadAppButton>
              <span className="download-lbl">Download the muvit app on </span>
            </DownloadAppButton>
          </div>
          <div className="on-board-step" data-value="2">
            <h2>Real time tracking</h2>
            <p>
              Our verified partner will ensure that your goods are transported
              safely to the destination. You can monitor the trip with live
              vehicle tracking and regular sms/email updates from our MOBILE APP{" "}
            </p>
          </div>
          <div className="on-board-step" data-value="3">
            <h2>Rate your experience</h2>
            <p>
              Choose your pickup &amp; drop location and select the desired
              vehicle. We’ll take care of the rest
            </p>
          </div>
        </div>
        <div className="app--item--image">
          <img src={BookRide} />
        </div>
      </div>
    </section>
    <section className="what-we-offer bg-gradient-left-cut">
      <h2 className="section--content--header text-color-white">
        What we offer
      </h2>
      <div className="section--content flex-section">
        <div className="section--content--item">
          <div className="section--content--item--icon">
            <img src={MobilePhone} width={100} />
          </div>
          <span className="item--sub--header">Instant Price Quote</span>
          <p>
            No hidden charges. see what a delivery cost looks like before you
            order
          </p>
        </div>
        <div className="section--content--item">
          <div className="section--content--item--icon">
            <img src={Support} />
          </div>

          <span className="item--sub--header">24/7 Service</span>
          <p>
            Having any problem? We are always a phone call away to have a
            conversation.
          </p>
        </div>
        <div className="section--content--item">
          <div className="section--content--item--icon">
            <img src={Connect} />
          </div>

          <span className="item--sub--header">Smart Matching</span>
          <p>
            OUr system automatically matches you with a driver that suits your
          </p>
        </div>
        <div className="section--content--item">
          <div className="section--content--item--icon">
            <img src={RealtimeTracking} />
          </div>

          <span className="item--sub--header">Real-Time Tracking</span>
          <p>Track your orders from anywhere. we update you in real time </p>
        </div>
      </div>
    </section>
    <section className="our-partners mt-50">
      <h2 className="section--content--header center">Become Our Partner</h2>
      <div className="section--content">
        <div className="partners-section-image">
          <div className="partner--signup--image">
            <img src={PartnerImage} />
          </div>
        </div>
        <div className="partners-section-content">
          <h2>Own a vehicle and ready to earn the smart way?</h2>
          <p>
            Register your vehicle on Muvit and watch how your Vehicle makes
            money for you the hassle free way.{" "}
          </p>
          <button className="cta cta--partner--signup">
            <Link to="/business">Learn More</Link>
          </button>
        </div>
      </div>
    </section>
    <section className="our-services">
      <h2 className="section--content--header text-color-white center">
        Our services
      </h2>
      <div className="section--content">
        <div className="services-grid">
          <div className="service-header-text">
            <p>
              At Muvit we offer a Comprehensive Service Pack Which Suits Your
              specific delivery need, keeping your kind of business in
              consideration No matter what you are moving furniture, supplies,
              food, goods, our wide range of vehicle types from motorcycles,
              buses to 30 ton trucks can help.
            </p>
          </div>
          <div className="services-items">
            <div className="services--item">
              <div className="services--item--icon">
                <img src={ItemMovement} />
              </div>

              <span className="item--sub--header">Ride with your items</span>
              <p>
                We give you an option to ride with your items. We are here to
                ensure we get your stuff to where you want, regardless of where
                you are, sit back relax and let Muvit handle all your delivery
                needs
              </p>
            </div>
            <div className="services--item">
              <div className="services--item--icon">
                <img src={HugeItem} />
              </div>

              <span className="item--sub--header">Huge items</span>
              <p>
                We will allocate the most suitable vehicle to transport your
                goods according to your needs
              </p>
            </div>
          </div>
        </div>
        <div className="services-row-2">
          <div className=""></div>
          <div className="services-items">
            <div className="services--item">
              <div className="services--item--icon">
                <img src={MovingSupplies} />
              </div>

              <span className="item--sub--header">Moving supplies</span>
              <p>
                Different materials have different and specific ways of handling
                them, having problems with that? No hassle Muvit will handle
                that.
              </p>
            </div>
            <div className="services--item">
              <div className="services--item--icon">
                <img src={Transport} />
              </div>

              <span className="item--sub--header">Transportation</span>
              <p>
                We have an un-parallel coverage so you can be rest assured we
                will deliver.
              </p>
            </div>
          </div>
          <div className="services-items">
            <div className="services--item">
              <div className="services--item--icon">
                <img src={Service} />
              </div>

              <span className="item--sub--header">Comprehensive Service</span>
              <p>
                It doesn't matter if you are moving furniture, supplies, goods,
                electronics, and home appliances. Our wide range of vehicle
                types from Motorcycles to 30 ton trucks can help.
              </p>
            </div>
            <div className="services--item">
              <div className="services--item--icon">
                <img src={Distribution} />
              </div>
              <span className="item--sub--header">Distribution</span>
              <p>
                We offer different categories so you can choose a category that
                best suits your need.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="faq-direct-block">
      <h2 class="header--section">Some of our Trusted Clients.</h2>
      <div className="section--content">
        <div className="partners-section">
          <ul class="partner-list">
            {/* <li><img src={partner1} /></li> */}
            <li>
              <img src={partner2} />
            </li>
            <li>
              <img src={partner3} />
            </li>
            <li>
              <img src={partner4} />
            </li>
            <li>
              <img src={partner5} />
            </li>
            <li>
              <img src={partner6} />
            </li>
            <li>
              <img src={partner7} />
            </li>
            <li>
              <img src={partner8} />
            </li>
            <li>
              <img src={partner9} />
            </li>
            <li>
              <img src={partner10} />
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
